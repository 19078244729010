import { IGroups } from 'interfaces/groups.interfaces';
import { uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

const initialGroupData: IGroups = {
  id: '',
  name: '',
  type: 'PERSONAL',
  participants: [],
  participantsWithAccess: [],
};

interface IProps {
  groupData: IGroups | null;
  isLoading?: boolean;
  groupsList: IGroups[];
}

const useDataGroup = ({ groupData, groupsList, isLoading }: IProps) => {
  const [data, setData] = useState<IGroups>(groupData ?? initialGroupData);
  const [newNameGroup, setNewNameGroup] = useState('');

  const createAction = !groupData;

  useEffect(() => {
    if (groupData) {
      setNewNameGroup(groupData.name);
    }
  }, [groupsList]);

  useEffect(() => {
    if (!groupData) {
      setData({ ...initialGroupData, name: newNameGroup.trim() });
      return;
    }
    setData({
      ...groupData,
      name: newNameGroup ? newNameGroup.trim() : groupData.name,
    });
  }, [groupData, newNameGroup]);

  const handleData = (name: string, value: unknown) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const paramsCreateGroup = useMemo(() => {
    const groupParticipantsIds = data.participants.map((item) => {
      return { userId: item.id, permission: [] };
    });
    const groupParticipantsWithAccessIds = data.participantsWithAccess.map((item) => {
      return { userId: item.id, permissions: ['READ'] };
    });

    const newData: any = {
      name: data.name,
      type: 'PERSONAL',
      groupParticipants: uniqBy([...groupParticipantsWithAccessIds, ...groupParticipantsIds], 'userId'),
    };
    return newData;
  }, [data]);

  return useMemo(
    () => ({
      createAction,
      data,
      setNewNameGroup,
      newNameGroup,
      handleData,
      paramsCreateGroup,
    }),
    [groupData, newNameGroup, data],
  );
};

export default useDataGroup;
