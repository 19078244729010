export const titleGroups = 'groups';
export const headerGroups = [
  { name: 'Все', key: 'ALL_IN_SPACE' },
  { name: 'Персональные', key: 'PERSONAL' },
];
export const TEXT_DELETE_NOTIFICATION_GROUP = 'Уверены, что хотите удалить группу?';
export const TEXT_DELETE_DESCRIPTION_GROUP = 'Восстановить группу будет невозможно';
export const TEXT_VALIDATION_NAME_GROUP = 'Такое название группы уже существует. Измените название.';
export const TEXT_FAILED_CREATED_GROUP = 'Не удалось добавить группу';
export const TEXT_CREATED_GROUP = 'Группа успешно создана';
export const TEXT_DELETED_GROUP = 'Группа удалена';
