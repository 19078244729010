import React, { useState, useEffect, useRef, useMemo, Dispatch, MutableRefObject } from 'react';

import { ControlBlock } from '@components/shared/controlBlock';
import { useDispatch } from 'react-redux';
import { LIMIT_PAGE } from '@components/constants/constants';
import { usePagination } from '../../../hooks/usePagination';
import { debounce } from 'lodash';
import { textDeleteNotificationAssist, textDeleteNotificationManager, titleAssistants } from './constants';
import TableAssist from './components/TableAssist';
import { useAppSelector } from '@store/hooks';
import TabsBarAssist from './components/TabsBarAssist';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import { selectAssistSlice, setState } from '@store/assistants/assistants.slice';
import ModalAssist from '../month/components/modalAssist/ModalAssist';
import EmptyPage from './components/EmptyPage';
import PopupModal from '@ui/modal/PopupModal';
import Notification from '@ui/notification/Notification';
import { truncateName } from '@utils/formattedText';
import {
  IAddAssist,
  useAddAssistsMutation,
  useDeleteAssistsMutation,
  useGetAssistListQuery,
  useGetManagerListQuery,
  useUpdateAssistsMutation,
  useUpdateManagerMutation,
} from '@store/assistants/assistant.api';
import { useGetUsersListQuery } from '@store/user/user.api';
import { toast } from 'react-toastify';
import notify from '@utils/notify';

const initialUserParams = new URLSearchParams({
  thirdPartyUsersOnly: 'false',
}).toString();

export const Assistants = () => {
  const [tabActive, setTabActive] = useState(false);
  const [openCreateAssist, setCreateAssist] = useState<boolean | null>(false);
  const [openUpdateAssist, setUpdateAssist] = useState<boolean | null>(false);
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [search, setSearch] = useState('');
  const [searchAssist, setSearchAssist] = useState('');

  const [userQueryParams, setUserQueryParams] = useState<string>(initialUserParams);
  const [assistQueryParams, setAssistQueryParams] = useState<string>('');
  const [managerQueryParams, setManagerQueryParams] = useState<string>('');

  const { currentDataForUpdate, currentDataForUpdateManager, checkedDataForUpdate } = useAppSelector(selectAssistSlice);

  const touchedSearchRef = useRef(false);
  if (search) touchedSearchRef.current = true;
  const touchedSearchRefAssist = useRef(false);
  if (searchAssist) touchedSearchRefAssist.current = true;

  const dispatch = useDispatch();

  const { data: fullAssistsList, isLoading, isFetching } = useGetAssistListQuery(assistQueryParams);
  const { data: fullManagersList, isFetching: isFetchingManager } = useGetManagerListQuery(managerQueryParams);
  const { data: users, isFetching: isLoadingUsers } = useGetUsersListQuery(userQueryParams, {
    skip: !openCreateAssist && !openUpdateAssist,
  });

  const [addAssist, addAssistResult] = useAddAssistsMutation();
  const [deleteAssist, deleteAssistResult] = useDeleteAssistsMutation();
  const [updateAssist, updateAssistResult] = useUpdateAssistsMutation();
  const [updateManager, updateManagerResult] = useUpdateManagerMutation();

  const { nextLoad, start } = usePagination(
    tabActive ? fullManagersList?.length ?? 0 : fullAssistsList?.length ?? 0,
    LIMIT_PAGE,
  );
  const debounceValueCommon = debounce((value: string, action: Dispatch<React.SetStateAction<string>>) => {
    action(value);
  }, 2000);

  const debounceValueReset = debounce((value: boolean, obj: MutableRefObject<boolean>) => {
    obj.current = value;
  }, 2000);

  useEffect(() => {
    if (search || touchedSearchRef.current) {
      const paramsString = new URLSearchParams({
        query: search,
        thirdPartyUsersOnly: 'false',
      }).toString();
      setUserQueryParams(paramsString);
    }
  }, [search]);

  useEffect(() => {
    if (touchedSearchRef.current && !search) {
      debounceValueReset(false, touchedSearchRef);
    }
    if (touchedSearchRefAssist.current && !searchAssist) {
      debounceValueReset(false, touchedSearchRefAssist);
    }
  }, [search, searchAssist, debounceValueReset]);

  useEffect(() => {
    if (searchAssist || touchedSearchRefAssist.current) {
      const paramsString = new URLSearchParams({
        query: searchAssist,
      }).toString();
      if (tabActive) {
        setManagerQueryParams(paramsString);
      } else {
        setAssistQueryParams(paramsString);
      }
    }
  }, [searchAssist, tabActive]);

  useEffect(() => {
    if (addAssistResult.isSuccess) {
      toast.success('Ассистент успешно добавлен', {
        draggable: true,
        position: 'top-center',
      });
      setCreateAssist(false);
    }

    if (addAssistResult.error) {
      notify.error('Не удалось добавить ассистента');
    }
  }, [addAssistResult]);

  useEffect(() => {
    if (deleteAssistResult.isSuccess) {
      toast.success('Ассистент исключен', {
        draggable: true,
        position: 'top-center',
      });
    }

    if (deleteAssistResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [deleteAssistResult]);

  useEffect(() => {
    if (updateAssistResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [updateAssistResult]);

  useEffect(() => {
    if (updateManagerResult.error) {
      notify.error('Не удалось обновить данные');
    }
  }, [updateManagerResult]);

  function handleSearchUsers(value: string) {
    debounceValueCommon(value, setSearch);
  }

  function handleSearchAssist(value: string) {
    debounceValueCommon(value, setSearchAssist);
  }

  function handleSetTabActive() {
    setTabActive(!tabActive);
    setSearch('');
    setSearchAssist('');
    setAssistQueryParams('');
    setManagerQueryParams('');
  }

  function handleAddAssist(managerIds: string[], assistIds: string[]) {
    const newData: IAddAssist[] = [
      {
        assistantId: assistIds[0],
        managerIds: managerIds,
      },
    ];
    addAssist(newData);
  }

  function handleDeleteAssist() {
    const assistantId = currentDataForUpdate?.assistant.id;
    const managerId = currentDataForUpdateManager?.manager.id;
    const managerIds = currentDataForUpdate?.managers.map((manager) => manager.id);
    const assistantIds = currentDataForUpdateManager?.assistants.map((assist) => assist.id);
    const assistNewData =
      currentDataForUpdate && assistantId && managerIds
        ? [
            {
              assistantId,
              managerIds,
            },
          ]
        : currentDataForUpdateManager &&
          assistantIds &&
          managerId && [...assistantIds.map((assistantId) => ({ assistantId: assistantId, managerIds: [managerId] }))];

    deleteAssist(assistNewData as IAddAssist[]);
    dispatch(
      setState({
        currentDataForUpdate: null,
      }),
    );
    setOpenNotification(false);
  }

  function handleUpdateAssist() {
    const assistantId = currentDataForUpdate?.assistant.id;
    const managerId = currentDataForUpdateManager?.manager.id;
    const checkedIds = checkedDataForUpdate.map((user) => user.id);
    if (!tabActive && assistantId) {
      const newData = [
        {
          assistantId,
          managerIds: checkedIds,
        },
      ];
      updateAssist(newData);
    }
    if (tabActive && managerId && checkedIds?.length) {
      const newDataManager = [
        {
          managerId,
          assistantIds: checkedIds,
        },
      ];
      updateManager(newDataManager);
    }
    dispatch(
      setState({
        currentDataForUpdate: null,
        currentDataForUpdateManager: null,
      }),
    );
    setUpdateAssist(false);
  }

  function handleGetMoreAssist(goBack?: boolean) {
    nextLoad(goBack);
    const paramsString = new URLSearchParams({
      query: searchAssist,
      page: `${goBack ? start - 2 : start}`,
    }).toString();
    if ((tabActive && fullManagersList?.length && Number.isInteger(fullManagersList?.length / LIMIT_PAGE)) || goBack) {
      return setManagerQueryParams(paramsString);
    }
    if ((!tabActive && fullAssistsList?.length && Number.isInteger(fullAssistsList?.length / LIMIT_PAGE)) || goBack) {
      return setAssistQueryParams(paramsString);
    }
  }

  const currentName = useMemo(() => {
    const nameAssist = `${currentDataForUpdate?.assistant?.lastName} ${currentDataForUpdate?.assistant?.firstName} ${currentDataForUpdate?.assistant?.middleName}`;
    const nameManager = `${currentDataForUpdateManager?.manager?.lastName} ${currentDataForUpdateManager?.manager?.firstName} ${currentDataForUpdateManager?.manager?.middleName}`;
    return currentDataForUpdate ? nameAssist : nameManager;
  }, [currentDataForUpdate, currentDataForUpdateManager]);

  const currentNameShort = useMemo(() => {
    const nameAssist = `${currentDataForUpdate?.assistant?.lastName} ${truncateName(
      currentDataForUpdate?.assistant.firstName,
    )} ${truncateName(currentDataForUpdate?.assistant.middleName)}`;
    const nameManager = `${currentDataForUpdateManager?.manager?.lastName} ${truncateName(
      currentDataForUpdateManager?.manager?.firstName,
    )} ${truncateName(currentDataForUpdateManager?.manager?.middleName)}`;
    return currentDataForUpdate ? nameAssist : nameManager;
  }, [currentDataForUpdate, currentDataForUpdateManager]);

  const currentCheckedData = useMemo(() => {
    return currentDataForUpdate ? currentDataForUpdate?.managers : currentDataForUpdateManager?.assistants;
  }, [currentDataForUpdate, currentDataForUpdateManager]);

  return (
    <>
      <ControlBlock
        title={titleAssistants}
        isAdmin={true}
        openForm={setCreateAssist}
        handleSearch={handleSearchAssist}
        textButton="addAssist"
        openTab={tabActive}
        isLoading={touchedSearchRefAssist.current && (isFetching || isFetchingManager)}
        searchValue={searchAssist}
      />
      <TabsBarAssist setTabActive={handleSetTabActive} tabActive={tabActive} />
      <TableAssist
        assistList={fullAssistsList ?? []}
        managersList={fullManagersList ?? []}
        isLoading={isLoading}
        handleGetAssist={handleGetMoreAssist}
        handleDeleteAssist={handleDeleteAssist}
        setModalOpen={setUpdateAssist}
        setModalOpenDown={setOpenNotification}
        handleGetIdPassword={() => undefined}
        openTab={tabActive}
        isLoadingExpand={!touchedSearchRefAssist.current && isFetching}
        nextPage={start}
      />
      <RenderWithCondition condition={!fullAssistsList?.length && !touchedSearchRefAssist}>
        <EmptyPage openForm={setCreateAssist} />
      </RenderWithCondition>

      <ModalAssist
        isOpenPopup={openCreateAssist}
        setOpenPopup={setCreateAssist}
        textTitle="addAssist"
        onSave={handleAddAssist}
        handleSearch={handleSearchUsers}
        searchValue={search}
        users={users ?? []}
        isLoadingUsers={isLoadingUsers}
      />
      <ModalAssist
        isOpenPopup={openUpdateAssist}
        setOpenPopup={setUpdateAssist}
        textTitle="changeList"
        onSave={handleUpdateAssist}
        handleSearch={handleSearchUsers}
        searchValue={search}
        tabActive={tabActive}
        users={users ?? []}
        assistantName={currentName}
        checkedData={currentCheckedData}
        isLoadingUsers={isLoadingUsers}
      />
      <PopupModal isShown={openNotification} closeEvent={(v) => setOpenNotification(v)}>
        <Notification
          textTitle={tabActive ? textDeleteNotificationManager : textDeleteNotificationAssist}
          textName={`${currentNameShort}`}
          onDone={handleDeleteAssist}
          onCancel={() => setOpenNotification(false)}
        />
      </PopupModal>
    </>
  );
};

export default Assistants;
