import React, { FC } from 'react';

import { titlesOthers } from './constants';
import { ButtonShowUsers } from '@components/shared/buttonShowUsers';
import { IStateUser } from '../../../../../interfaces/user.interfaces';
import { FormattedMessage } from 'react-intl';
import { TableItemOthers } from './TableItemOthers';
import { LIMIT_PAGE } from '@components/constants/constants';
import { LocalLoader } from '@ui/loader/LocalLoader';
import { uniqueId } from 'lodash';
import './tableOthers.scss';

interface ITableOthers {
  isLoading: boolean;
  isLoadingExpand?: boolean;
  othersList: IStateUser[];
  handleDelete: (id: string) => void;
  handleAddEmployees: (id: string) => void;
  handleGetOthers: () => void;
  handleChangeTest: (id: string, login: string, test: boolean) => void;
  isNotSuccessUpdate?: boolean;
  nextPage: number;
}

export const TableOthers: FC<ITableOthers> = ({
  isLoading,
  othersList,
  handleDelete,
  isLoadingExpand,
  handleGetOthers,
  handleChangeTest,
  handleAddEmployees,
  isNotSuccessUpdate,
  nextPage,
}: ITableOthers) => {
  const isNext = othersList?.length ? Number.isInteger(othersList.length / LIMIT_PAGE) : false;

  return (
    <div className="others-wrapper">
      <div className="others">
        <div className="others-header">
          {titlesOthers.map((title) => (
            <div className="others-header-title" key={uniqueId(title)}>
              <FormattedMessage id={title} />
            </div>
          ))}
        </div>
        <div className="others-body">
          {othersList.map((user, index) => (
            <TableItemOthers
              user={user}
              index={index}
              key={user.id}
              handleDelete={handleDelete}
              handleAddEmployees={handleAddEmployees}
              onChangeTest={handleChangeTest}
              isNotSuccessUpdate={isNotSuccessUpdate}
            />
          ))}
          <ButtonShowUsers
            title={'showMore'}
            isActive={isNext}
            loading={isLoadingExpand}
            handleEvents={handleGetOthers}
            nextPage={nextPage}
          />
        </div>
        <LocalLoader condition={isLoading} />
      </div>
    </div>
  );
};
