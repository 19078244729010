import React from 'react';

import { titlesTable, titlesTableAdmin } from './constants';
import { ButtonShowUsers } from '@components/shared/buttonShowUsers';
import { TableItem } from './TableItem';
import { LIMIT_PAGE } from '@components/constants/constants';
import { IStateUser } from 'interfaces/user.interfaces';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import './table.scss';
import { LocalLoader } from '@ui/loader/LocalLoader';

interface ITable {
  usersList: IStateUser[];
  supervisor?: boolean;
  isLoading: boolean;
  isLoadingExpand?: boolean;
  handleGetUsers: () => void;
  handleDeleteUser?: (id: string) => void;
  handleChangeTest?: (id: string, login: string, test: boolean) => void;
  handleChangeAccess?: (id: string, login: string, isEnabled: boolean) => void;
  handleExcludeUser?: (id: string, spaceId: string) => void;
  isNotSuccessUpdate?: boolean;
  nextPage: number;
}

export const Table = ({
  usersList,
  isLoading,
  handleGetUsers,
  supervisor,
  isLoadingExpand,
  handleExcludeUser,
  handleChangeTest,
  handleChangeAccess,
  isNotSuccessUpdate,
  nextPage,
}: ITable) => {
  const isNext = usersList.length ? Number.isInteger(usersList.length / LIMIT_PAGE) : false;
  const titlesMap = supervisor ? titlesTable : titlesTableAdmin;

  return (
    <div className="table-wrapper">
      <div className="table">
        <div className={cn('table-header', { supervisor: supervisor })}>
          <div />
          {titlesMap.map((title) => (
            <div className="table-header-title" key={title}>
              <FormattedMessage id={title} />
            </div>
          ))}
        </div>
        <div className={cn('table-body', { supervisor: supervisor })}>
          {usersList &&
            usersList.map((user, index) => (
              <TableItem
                user={user}
                index={index}
                key={user.id}
                isLoading={isLoading}
                supervisor={supervisor}
                onChangeTest={handleChangeTest}
                onChangeAccess={handleChangeAccess}
                handleExcludeUser={handleExcludeUser}
                isNotSuccessUpdate={isNotSuccessUpdate}
              />
            ))}
          <ButtonShowUsers
            isActive={isNext}
            title={'showMore'}
            loading={isLoadingExpand}
            handleEvents={handleGetUsers}
            nextPage={nextPage}
          />
        </div>
        <LocalLoader condition={isLoading} />
      </div>
    </div>
  );
};
