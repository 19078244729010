import React, { useState, useEffect, Dispatch } from 'react';

import { ControlBlock } from '@components/shared/controlBlock';
import { debounce } from 'lodash';
import { titleGroups } from './constants';
import ModalAssist from '../month/components/modalAssist/ModalAssist';
import TableGroups from './components/TableGroups';
import { useGetGroupsListQuery } from '@store/groups/groups.api';
import { useAppSelector } from '@store/hooks';
import { selectUserSlice } from '@store/user/slice';
import { usePagination } from '@hooks/usePagination';
import { LIMIT_PAGE } from '@components/constants/constants';

const initialUserParams = new URLSearchParams({
  page: '0',
  size: '20',
}).toString();

export const Groups = () => {
  const [searchGroups, setSearchGroups] = useState('');
  const [groupsQueryParams, setGroupsQueryParams] = useState<string>(initialUserParams);
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [openCreateGroup, setOpenCreateGroup] = useState<boolean | null>(false);

  const { data: groupsList, isFetching: isLoadingGroups } = useGetGroupsListQuery(groupsQueryParams, {
    skip: !groupsQueryParams,
  });

  const { nextLoad, start } = usePagination(groupsList?.length ?? 0, LIMIT_PAGE);

  const { filterGroups } = useAppSelector(selectUserSlice);

  const debounceValue = debounce((value) => {
    setSearchGroups(value);
    const paramsString = new URLSearchParams({
      query: value,
    });
    filterGroups.forEach((item) => paramsString.append('type', item));
    setGroupsQueryParams(paramsString.toString());
  }, 2000);

  function handleSearchUsers(value: string) {
    debounceValue(value);
  }

  function handleGetMoreGroups(goBack?: boolean) {
    nextLoad(goBack);
    const newParamsString = new URLSearchParams({
      query: searchGroups,
      page: `${goBack ? start - 2 : start}`,
    }).toString();
    if ((groupsList?.length && Number.isInteger(groupsList?.length / LIMIT_PAGE)) || goBack) {
      setGroupsQueryParams(newParamsString);
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  useEffect(() => {
    let paramsString = new URLSearchParams({
      query: searchGroups,
    });
    filterGroups.forEach((item) => paramsString.append('type', item));
    setGroupsQueryParams(paramsString.toString());
  }, [filterGroups]);

  return (
    <>
      <ControlBlock
        title={titleGroups}
        isAdmin={true}
        openForm={setOpenNotification}
        handleSearch={() => undefined}
        textButton="createGroup"
        noSearch
        onlyTitle
      />
      <TableGroups
        handleSearch={handleSearchUsers}
        searchValue={searchGroups}
        groupsList={groupsList ?? []}
        isLoading={isLoadingGroups}
        handleGetUsers={handleGetMoreGroups}
        nextPage={start}
      />

      <ModalAssist
        isOpenPopup={openCreateGroup}
        setOpenPopup={setOpenCreateGroup}
        textTitle="addAssist"
        onSave={() => undefined}
        handleSearch={() => undefined}
        isLoadingUsers={isLoadingGroups}
      />
    </>
  );
};

export default Groups;
