import { useCallback, useState } from 'react';

const nextLoadAvailable = (length: number, limit: number) => length && Number.isInteger(length / limit);

export const usePagination = (dataLength: number, limitInitial: number) => {
  const [start, setStart] = useState(1);

  const nextLoad = useCallback(
    (goBack?: boolean) => {
      setStart(goBack ? start - 1 : start + 1);
    },
    [dataLength, start, limitInitial],
  );

  return {
    nextLoad,
    start,
    setStart,
  };
};
