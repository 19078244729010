import React, { FC } from 'react';

import { LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { ButtonShowUsers } from '@components/shared/buttonShowUsers';
import { useNavigate } from 'react-router-dom';
import { ItemTableLine } from './ItemTableLine';
import { IPlaningBusinessTasks } from '../../hooks/useTableUserStatistics';
import { useWidthScreens } from '../../../../../hooks/useWidthScreens';
import { mobile, tablet } from '@components/constants/constants';
import styled from 'styled-components';

interface IPlaningBusinessGoals {
  data: IPlaningBusinessTasks;
  userName: string;
}

export const PlaningBusinessGoals: FC<IPlaningBusinessGoals> = (props) => {
  const navigate = useNavigate();

  const { width } = useWidthScreens();

  return (
    <Container className="flex  flex-col gap-[60px]">
      <div className="flex  flex-col gap-[20px]">
        <LineText fontSize={width !== null && width > 1200 ? '32px' : '28px'}>
          <FormattedMessage id="Планирование бизнес-задач" />
        </LineText>
        <ItemTableLine data={props.data.mapTotalPlaningBusinessTasks} />
        <ItemTableLine data={props.data.mapCurrentBusinessTasks} />
      </div>

      <div className="flex  flex-col gap-[12px]">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="Итоги по запланированным дням работы по задаче" />
        </LineText>
        <ItemTableLine data={props.data.mapResultsOfWorkDays} />
        <ButtonShowUsers
          title={'showChartsDynamic'}
          isActive={true}
          handleEvents={() => navigate('/employees/month/dynamics')}
          padding="0px 0px"
          isHiddenBackBtn
        />
      </div>

      <div className="flex  flex-col gap-[12px]">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="Создание задач" />
        </LineText>
        <ItemTableLine data={props.data.mapCreatingBusinessTasks} />
      </div>

      {/* ========== TODO:  Temporary Hidden ========== */}
      {/* <div className="flex  flex-col gap-[12px]">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="Приостановленные задачи" />
        </LineText>
        <ItemTableLine data={props.data.mapSuspendedBusinessTasks} />
      </div> */}

      <div className="flex  flex-col gap-[12px]">
        <LineText fontSize={'20px'} bold={'700'}>
          <FormattedMessage id="Закрытые задачи" />
        </LineText>
        <ItemTableLine data={props.data.mapClosedBusinessTasks} userName={props.userName} />
      </div>
    </Container>
  );
};

export const Container = styled.div`
  flex: 1;
  @media screen and (max-width: ${tablet}px) {
    gap: 32px;
  }
  @media screen and (max-width: ${mobile}px) {
    gap: 24px;
  }
`;
