import React, { ChangeEvent, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LineText } from '@components/commons/commonsStyled';
import { tablet } from '@components/constants/constants';
import { colorsVar } from '@components/constants/colorVariables';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';
import styled from 'styled-components';

interface IProps {
  color?: string;
}
interface IModalNameGroup {
  textTitle: string;
  colorButton?: string;
  onDone: () => void;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  textButton: string;
  handleChange: (value: string) => void;
  inputValue: string;
}

const ModalNameGroup: FC<IModalNameGroup> = ({
  textTitle,
  handleChange,
  inputValue,
  onDone,
  colorButton,
  textButton,
  onCancel,
}: IModalNameGroup) => {
  const intl = useIntl();

  return (
    <Container className="flex">
      <div className="flex flex-col gap-8">
        <Title className="flex">
          <LineText fontSize="28px" lineHeight="32px">
            <FormattedMessage id={textTitle} />
          </LineText>
        </Title>

        <Input
          id="password"
          onChange={(event) => handleChange(event.target.value)}
          value={inputValue}
          placeholder={intl.formatMessage({ id: 'enterTitle' })}
          autoFocus={true}
        />
      </div>

      <ButtonsGroup className="flex">
        <Button className="flex" onClick={onDone} color={colorButton} disabled={!inputValue}>
          <FormattedMessage id={textButton} />
        </Button>
        <ButtonCancel className="flex" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </ButtonCancel>
      </ButtonsGroup>
    </Container>
  );
};

export default ModalNameGroup;

export const Button = styled.button<IProps>`
  justify-content: center;
  align-items: center;
  width: 160px;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  color: ${colorsVar.white};
  background-color: ${(props) => (props.color ? props.color : `${colorsVar.greenBase}`)};
  transition: all 0.5s ease;
  &:hover {
    background-color: ${(props) => (props.color ? props.color : `${colorsVar.greenHover}`)};
    opacity: ${(props) => (props.color ? 0.6 : 1)};
    transition: all 0.5s ease;
  }
  @media screen and (max-width: ${tablet}px) {
    width: 100%;
  }
`;
const ButtonCancel = styled.button`
  justify-content: center;
  align-items: center;
  width: 160px;
  font-size: 18px;
  background-color: ${colorsVar.white};
  border: 1px solid ${colorsVar.greenBase};
  color: ${colorsVar.greenBase};
  border-radius: 10px;
  transition: all 0.5s ease;
  &:hover {
    border: 1px solid ${colorsVar.greenClose};
    color: ${colorsVar.greenClose};
    background-color: ${colorsVar.grey5};
    transition: all 0.5s ease;
  }
  @media screen and (max-width: ${tablet}px) {
    width: 100%;
  }
`;
const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  padding: 0 30px;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: ${colorsVar.textColor};
`;
const Input = styled.input`
  border: 1px solid ${colorsVar.grey4};
  border-radius: 10px;
  padding: 6px 10px;
  height: 43px;
  outline: none;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  &::placeholder {
    color: ${colorsVar.grey4};
  }
`;
const Container = styled.div`
  display: flex;
  width: 445px;
  flex-direction: column;
  gap: 35px;
  background: ${colorsVar.white};
  border-radius: 20px;
  /* padding: 0px 45px; */
  box-sizing: border-box;
  @media screen and (max-width: ${tablet}px) {
    width: 100%;
    padding: 25px 0px;
    min-height: 180px;
  }
`;
