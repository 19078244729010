import React, { FC, useState } from 'react';

import { ContainerTable, LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { uniqueId } from 'lodash';
import { ITotalPlaningBusinessTasks } from 'interfaces/stats.interfaces';
import styled, { keyframes } from 'styled-components';

interface IItemTableLine {
  data: ITotalPlaningBusinessTasks[];
  userName?: string;
}

export const ItemTableLine: FC<IItemTableLine> = (props) => {
  const [openFields, setOpenFields] = useState(false);

  return (
    <ContainerTable>
      {Array.isArray(props.data) &&
        props.data.map((item) => {
          if (item.accord)
            return (
              <>
                <LineTableAccord
                  active={openFields}
                  className="justify-start cursor-pointer"
                  key={uniqueId(item.title)}
                  onClick={() => setOpenFields(!openFields)}
                >
                  <LineText fontSize={'15px'} leftIndent={item.leftIndent} bold={item.bold}>
                    <FormattedMessage id={item.title} /> {item.isUserName && props.userName}
                  </LineText>
                  <LineText fontSize={'15px'} bold={'700'}>
                    {item.svg}
                  </LineText>
                </LineTableAccord>
                <AccordWrapper active={openFields}>
                  {item.accord.map((element, index) => (
                    <LineTableAccord className="justify-between" key={uniqueId(element.title)}>
                      <LineText
                        fontSize={'15px'}
                        leftIndent={element?.leftIndent}
                        bold={element?.bold}
                        color={element?.color}
                      >
                        <FormattedMessage id={element.title} /> {element.isUserName && props.userName}
                      </LineText>
                      <LineText fontSize={'15px'} bold={'700'} color={element?.color}>
                        {element.value}
                      </LineText>
                    </LineTableAccord>
                  ))}
                </AccordWrapper>
              </>
            );
          return (
            <LineTable className="justify-between" key={uniqueId(item.title)}>
              <LineText fontSize={'15px'} leftIndent={item?.leftIndent} bold={item?.bold}>
                <FormattedMessage id={item.title} /> {item.isUserName && props.userName}
              </LineText>
              <LineText fontSize={'15px'} bold={'700'}>
                {item.value}
              </LineText>
            </LineTable>
          );
        })}
    </ContainerTable>
  );
};

const rotateArrowAnimationUp = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
const rotateArrowAnimationDown = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const LineTable = styled.div<{ border?: boolean; active?: boolean }>`
  display: flex;
  width: 100%;
  gap: 20px 10px;
  align-items: center;
  padding: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #e3e9e7;
  }
`;
const LineTableAccord = styled.div<{ border?: boolean; active?: boolean }>`
  display: flex;
  width: 100%;
  gap: 20px 10px;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e3e9e7;

  svg {
    transform: rotate(0deg);
    animation: ${({ active }) => {
        return active ? rotateArrowAnimationUp : rotateArrowAnimationDown;
      }}
      500ms linear forwards;
  }
`;

const AccordWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    max-height: 120px;
    transition: max-height 0.5s ease;
  
  `}
`;
