import React, { FC, useEffect, useMemo, useState } from 'react';

import { ReactComponent as EraseIcon } from '@assets/icons/erase_icon.svg';
import styled from 'styled-components';
import { headerGroups } from '../constants';
import ChipMain from '@ui/chipMain/ChipMain';
import { IGroups } from 'interfaces/groups.interfaces';
import { useDispatch } from 'react-redux';
import { setFilterGroups } from '@store/user/slice';

interface IControlBlockGroups {
  groupsList: IGroups[];
}

const ControlBlockGroups: FC<IControlBlockGroups> = ({ groupsList }) => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFilterGroups(selectedGroups));
  }, [selectedGroups]);

  function handleSelectGroups(value: string) {
    if (value === 'drop') return setSelectedGroups([]);
    if (selectedGroups.includes(value)) {
      setSelectedGroups(selectedGroups.filter((item) => item !== value));
    } else {
      setSelectedGroups([...selectedGroups, value]);
    }
  }

  return (
    <>
      <Wrapper>
        <LineChips>
          {headerGroups.map((item) => (
            <ChipMain
              clickEvent={handleSelectGroups}
              buttonText={item.name}
              isDisabled={false}
              key={item.key}
              keyGroup={item.key}
              active={selectedGroups.includes(item.key)}
            />
          ))}
        </LineChips>
        <ChipMain
          clickEvent={() => handleSelectGroups('drop')}
          buttonText={'drop'}
          isDisabled={false}
          svgIconLeft={<EraseIcon />}
        />
      </Wrapper>
    </>
  );
};

export default ControlBlockGroups;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  min-height: 27px;
  box-sizing: border-box;
  margin-top: -8px;
`;
const LineChips = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;
`;
