import React, { useMemo, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';
import { IGroups } from 'interfaces/groups.interfaces';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/left_arrow.svg';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import ListParticipants from './ListParticipants.Group';
import { LineText } from '@components/commons/commonsStyled';
import { FormattedMessage } from 'react-intl';
import { useGetUsersListQuery } from '@store/user/user.api';
import { debounce, isEmpty, uniqBy } from 'lodash';
import { IStateUser } from 'interfaces/user.interfaces';
import { useUpdateParticipantsAccessGroupMutation, useUpdateParticipantsGroupMutation } from '@store/groups/groups.api';

const initialUserParams = new URLSearchParams({
  thirdPartyUsersOnly: 'false',
}).toString();

interface IItemGroupEdit {
  groupData: IGroups;
  isLoading?: boolean;
  onBack: () => void;
  edit: 'access' | 'participants';
  handleData: (key: string, value: any) => void;
  create?: boolean;
}

const ItemGroupEdit = ({ isLoading, groupData, onBack, edit, handleData, create = false }: IItemGroupEdit) => {
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [paramsString, setParamsString] = useState<string>(initialUserParams);
  const [search, setSearch] = useState('');
  const [searchParticipants, setSearchParticipants] = useState('');
  const [checkedUsers, setCheckedUsers] = useState<Partial<IStateUser>[]>([]);
  const [savedUsers, setSavedUsers] = useState<Partial<IStateUser>[]>([]);
  const [deletedUsers, setDeletedUsers] = useState<Partial<IStateUser>[]>([]);

  const { data: users, isLoading: loadUsers, isFetching } = useGetUsersListQuery(paramsString);
  const [updateParticipantsGroup, updateParticipantsGroupResult] = useUpdateParticipantsGroupMutation();
  const [
    updateParticipantsAccessGroup,
    updateParticipantsAccessGroupResult,
  ] = useUpdateParticipantsAccessGroupMutation();

  const debounceValueParticipants = debounce((value) => {
    setSearchParticipants(value);
  }, 1000);

  const debounceValue = debounce((value) => {
    setSearch(value);
    const paramsString = new URLSearchParams({
      query: value,
      thirdPartyUsersOnly: 'false',
    }).toString();
    setParamsString(paramsString);
  }, 2000);

  function handleSearchParticipants(value: string) {
    debounceValueParticipants(value);
  }

  function handleSearchUsers(value: string) {
    debounceValue(value);
  }

  function handleCheckedUsers(value: Partial<IStateUser>) {
    if (!value) return;
    if (checkedUsers.some((item) => item.id === value?.id)) {
      setCheckedUsers(checkedUsers.filter((user) => user.id !== value?.id));
      return;
    }
    setCheckedUsers([...checkedUsers, { ...value, isActiveTemp: true }]);
  }
  function handleCheckedAllUsers() {
    if (notCheckedUsers.length) {
      setCheckedUsers(
        uniqBy([...checkedUsers, ...chooseUsersList.map((user) => ({ ...user, isActiveTemp: true }))], 'id'),
      );
    } else {
      setCheckedUsers([]);
    }
  }

  function handleDeleteUser(value: Partial<IStateUser>) {
    setSavedUsers(savedUsers.filter((user) => user.id !== value?.id));
    if (edit === 'access') {
      handleData(
        'participantsWithAccess',
        groupData?.participantsWithAccess?.filter((user) => user.id !== value?.id),
      );

      return;
    }
    create
      ? handleData(
          'participants',
          groupData?.participants?.filter((user) => user.id !== value?.id),
        )
      : setDeletedUsers(uniqBy([...deletedUsers, { ...value }], 'id'));
  }
  function handleDeleteAllUsers() {
    setSavedUsers([]);
    if (edit === 'access') {
      handleData('participantsWithAccess', []);

      return;
    }
    handleData('participants', []);
  }

  function handleSave() {
    if (checkedUsers?.length) {
      setSavedUsers([...savedUsers, ...checkedUsers]);
      setCheckedUsers([]);
      return;
    } else {
      if (edit === 'access') {
        create
          ? handleData('participantsWithAccess', [...dataSavedUsersList])
          : updateParticipantsAccessGroup({
              id: groupData?.id,
              data: dataSavedUsersList.map((item) => {
                return { userId: item.id, permissions: ['READ'] };
              }),
            });
        handleData('participantsWithAccess', [...dataSavedUsersList]);
        onBack();
        return;
      }
      create
        ? handleData(
            'participants',
            dataSavedUsersList.map((user) => ({ ...user, isActiveTemp: false })),
          )
        : updateParticipantsGroup({
            id: groupData?.id,
            data: dataSavedUsersList.map((item) => item.id),
          });
      const paramsString = new URLSearchParams({
        query: groupData.name,
      });
      handleData(
        'participants',
        dataSavedUsersList.map((user) => ({ ...user, isActiveTemp: false })),
      );

      onBack();
    }
  }

  const participantsList = useMemo(() => {
    const createdGroupParticipants =
      groupData?.participants?.filter((item) => !deletedUsers.some((user) => user.id === item.id)) || [];

    if (!searchParticipants) return create ? groupData?.participants || [] : createdGroupParticipants;
    return create
      ? groupData?.participants?.filter((item) => JSON.stringify(item).includes(searchParticipants)) || []
      : createdGroupParticipants?.filter((item) => JSON.stringify(item).includes(searchParticipants));
  }, [groupData, searchParticipants, deletedUsers]);

  const accessRightList = useMemo(() => {
    if (!searchParticipants) return groupData?.participantsWithAccess || [];
    return groupData?.participantsWithAccess?.filter((item) => JSON.stringify(item).includes(searchParticipants)) || [];
  }, [groupData, searchParticipants]);

  const dataSavedUsersList = useMemo(() => {
    return edit === 'access' ? [...accessRightList, ...savedUsers] : [...participantsList, ...savedUsers] || [];
  }, [groupData, savedUsers, participantsList, deletedUsers]);

  const notCheckedUsers = useMemo(() => {
    const notSavedUsers =
      (users?.length && users?.filter((user) => !dataSavedUsersList?.find((item) => item.id === user.id))) || [];
    const notSavedUsersAccess =
      groupData?.participants?.filter((user) => !dataSavedUsersList?.find((item) => item.id === user.id)) || [];
    return edit === 'access' && !search
      ? notSavedUsersAccess?.filter((user) => !checkedUsers?.find((item) => item.id === user.id)) || []
      : notSavedUsers?.filter((user) => !checkedUsers?.find((item) => item.id === user.id)) || [];
  }, [users, checkedUsers, dataSavedUsersList]);

  const chooseUsersList = useMemo(() => {
    return uniqBy([...checkedUsers, ...notCheckedUsers], 'id') || [];
  }, [users, checkedUsers, dataSavedUsersList]);

  const headerTexts = useMemo(() => {
    switch (edit) {
      case 'participants':
        return { title: 'editParticipants', subTitleLeft: 'addParticipants', subTitleRight: 'participantsGroup' };
      case 'access':
        return { title: 'editAccess', subTitleLeft: 'addParticipantsAccess', subTitleRight: 'participantsAccess' };
      default:
        return { title: 'editParticipants', subTitleLeft: 'addParticipants', subTitleRight: 'participantsGroup' };
    }
  }, [edit]);
  const isActiveAllUsers = useMemo(() => {
    return !notCheckedUsers.length && !!chooseUsersList.length;
  }, [notCheckedUsers, chooseUsersList]);

  return (
    <ContentWrap>
      <HeaderWrap>
        <LeftBlock>
          <IconWrap onClick={onBack}>
            <ArrowLeftIcon />
          </IconWrap>
          <LineText fontSize="16px" color={colorsVar.textColor} bold={'700'} lineHeight="24px">
            <FormattedMessage id={headerTexts.title} />
          </LineText>
        </LeftBlock>
      </HeaderWrap>
      <div className="flex  gap-4">
        <ListParticipants
          titleText={headerTexts.subTitleLeft}
          dataList={chooseUsersList}
          editType="add"
          handleSearchUsers={handleSearchUsers}
          searchValue={search}
          changeCheckEvent={handleCheckedUsers}
          handleEventAll={handleCheckedAllUsers}
          activeAllCheck={isActiveAllUsers}
        />
        <ListParticipants
          titleText={headerTexts.subTitleRight}
          dataList={dataSavedUsersList}
          editType="delete"
          handleSearchUsers={handleSearchParticipants}
          searchValue={searchParticipants}
          changeCheckEvent={handleDeleteUser}
          handleEventAll={handleDeleteAllUsers}
          activeAllCheck={isActiveAllUsers}
        />
      </div>
      <ButtonWrap>
        <div className="w-[200px]">
          <ButtonMain clickEvent={handleSave} buttonText={checkedUsers.length ? 'add' : 'save'} />
        </div>
      </ButtonWrap>
    </ContentWrap>
  );
};

export default ItemGroupEdit;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 16px;
  width: 100%;
  align-items: center;
  background-color: ${colorsVar.grey3};
  height: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  padding: 4px 0px 30px;
  justify-content: end;
  height: 43px;
`;

const HeaderWrap = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorsVar.grey1};
  background: ${colorsVar.white};
`;

const LeftBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;
const IconWrap = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: 7px;
  &:hover {
    background-color: ${colorsVar.grey1};
    transition: background-color 0.5s;
  }
`;
