import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useStatsSelector } from '@store/statistic/selectors';
import { ReactComponent as HomeIcon } from '@assets/icons/home.svg';
import { ReactComponent as TheaterIcon } from '@assets/icons/theater.svg';
import { ReactComponent as CaseIcon } from '@assets/icons/briefcase.svg';
import { ReactComponent as BookIcon } from '@assets/icons/open-book.svg';
import { ReactComponent as EthicalIcon } from '@assets/icons/ethical_icon.svg';
import { ReactComponent as DumbIcon } from '@assets/icons/dumbbells.svg';
import { ReactComponent as UpArrowIcon } from '@assets/icons/arrow_up.svg';
import { useLocation } from 'react-router-dom';
import { chunk, isEmpty } from 'lodash';
import { IMapBusinessGoals, ITotalPlaningBusinessTasks } from 'interfaces/stats.interfaces';
import { CURRENT_USER_ID } from '@configs/consts';
import { IUserDetailedBusinessGoals } from 'interfaces/user.interfaces';
import { useGetUserMonthGoalQuery } from '@store/user/user.api';
import {
  useGetPlaningMeetingQuery,
  useGetUserBusinessGoalsQuery,
  useGetUserBusinessTasksQuery,
  useGetUserDetailedBusinessGoalsQuery,
  useGetUserPersonalGoalsQuery,
  useGetUserTasksOfDayQuery,
  useGetUserTasksOfMonthQuery,
} from '@store/month/month.api';
import { useAppSelector } from '@store/hooks';
import { selectUserSlice } from '@store/user/slice';

export interface IPlaningBusinessTasks {
  mapTotalPlaningBusinessTasks: ITotalPlaningBusinessTasks[];
  mapCurrentBusinessTasks: ITotalPlaningBusinessTasks[];
  mapResultsOfWorkDays: ITotalPlaningBusinessTasks[];
  mapCreatingBusinessTasks: ITotalPlaningBusinessTasks[];
  mapSuspendedBusinessTasks: ITotalPlaningBusinessTasks[];
  mapClosedBusinessTasks: ITotalPlaningBusinessTasks[];
}
export interface IDataUserMonth {
  mapPersonalGoals: IMapPersonalGoals[];
  textMonthGoal: string;
  mapBusinessGoals: IMapBusinessGoals[];
  planingBusinessTasks: IPlaningBusinessTasks;
  mapPlaningMeeting: ITotalPlaningBusinessTasks[];
  mapDelegatedBusinessTasks: ITotalPlaningBusinessTasks[];
  mapDetailedBusinessGoals: IUserDetailedBusinessGoals[];
  numbersOpenClosedBusinessGoals: string;
}
export interface ITableUserStatistics {
  dataUserMonth: IDataUserMonth;
  dataUserDay: IDataUserDay;
}
export interface IDataUserDay {
  mapTasksOfDay: IMapBusinessGoals[];
}
export interface IMapPersonalGoals {
  name: string;
  icon: JSX.Element;
  totalGoals: number;
  doneGoals: number;
}

const useTableUserStatistics = () => {
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [isSmScreen, setIsSmScreen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState('');
  const [paramsStatisticMonth, setParamsStatisticMonth] = useState<Record<string, string>>({});
  const [paramsStatisticDay, setParamsStatisticDay] = useState<Record<string, string>>({});

  const location = useLocation();

  const { currentUser, errorMsgUserMonthGoal, currentDate } = useAppSelector(selectUserSlice);

  const { data: userMonthGoal } = useGetUserMonthGoalQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });

  const { data: userPersonalGoals } = useGetUserPersonalGoalsQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });

  const { data: userBusinessGoals } = useGetUserBusinessGoalsQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });

  const { data: userBusinessTasks } = useGetUserBusinessTasksQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });

  const { data: userPlaningMeeting } = useGetPlaningMeetingQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });

  const { data: userTasksOfDay } = useGetUserTasksOfDayQuery(paramsStatisticDay, {
    skip: isEmpty(paramsStatisticDay),
  });

  const { data: userTasksOfMonth } = useGetUserTasksOfMonthQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });

  const { data: userDetailedBusinessGoals } = useGetUserDetailedBusinessGoalsQuery(paramsStatisticMonth, {
    skip: isEmpty(paramsStatisticMonth),
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1670px)');
    const handleMediaQueryChange = (event: any) => {
      setIsWideScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [location.pathname]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 850px)');
    const handleMediaQueryChange = (event: any) => {
      setIsSmScreen(event.matches);
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (currentUser?.id) {
      setCurrentUserId(currentUser?.id);
    } else {
      const id = localStorage.getItem(CURRENT_USER_ID);
      if (id) {
        setCurrentUserId(id);
      }
    }
  }, [currentUser?.id]);

  useEffect(() => {
    const paramsString = new URLSearchParams({
      yearMonth: currentDate.format('YYYY-MM'),
    }).toString();
    const paramsStringOfDay = new URLSearchParams({
      date: currentDate.format('YYYY-MM-DD'),
    }).toString();
    if (currentUserId) {
      setParamsStatisticMonth({ id: currentUserId, params: paramsString });
      setParamsStatisticDay({ id: currentUserId, params: paramsStringOfDay });
    }
  }, [currentDate, currentUserId]);

  const descriptionOfGoal = userMonthGoal?.description ?? 'Названия цели нет';
  const textMonthGoal = errorMsgUserMonthGoal ? errorMsgUserMonthGoal : descriptionOfGoal;

  const personalGoals: IMapPersonalGoals[] = React.useMemo(
    () => [
      {
        name: 'family',
        icon: <HomeIcon />,
        totalGoals: userPersonalGoals?.familyAndHomeGoals ?? 0,
        doneGoals: userPersonalGoals?.familyAndHomeDoneGoals ?? 0,
      },
      {
        name: 'education',
        icon: <BookIcon />,
        totalGoals: userPersonalGoals?.developmentAndSelfEducationGoals ?? 0,
        doneGoals: userPersonalGoals?.developmentAndSelfEducationDoneGoals ?? 0,
      },
      {
        name: 'social',
        icon: <TheaterIcon />,
        totalGoals: userPersonalGoals?.socialAndCulturalGoals ?? 0,
        doneGoals: userPersonalGoals?.socialAndCulturalDoneGoals ?? 0,
      },
      {
        name: 'ethics',
        icon: <EthicalIcon />,
        totalGoals: userPersonalGoals?.spiritualAndEthicalGoals ?? 0,
        doneGoals: userPersonalGoals?.spiritualAndEthicalDoneGoals ?? 0,
      },
      {
        name: 'finance',
        icon: <CaseIcon />,
        totalGoals: userPersonalGoals?.financeAndCareerGoals ?? 0,
        doneGoals: userPersonalGoals?.financeAndCareerDoneGoals ?? 0,
      },
      {
        name: 'health',
        icon: <DumbIcon />,
        totalGoals: userPersonalGoals?.physicalConditionAndHealthGoals ?? 0,
        doneGoals: userPersonalGoals?.physicalConditionAndHealthDoneGoals ?? 0,
      },
    ],
    [userPersonalGoals],
  );

  const mapPersonalGoalsTwoScreens = isWideScreen ? chunk(personalGoals, 2) : chunk(personalGoals, 3);
  const mapPersonalGoals = isSmScreen ? mapPersonalGoalsTwoScreens : chunk(personalGoals, 6);

  const mapBusinessGoals: IMapBusinessGoals[] = [
    { title: 'open', value: userBusinessGoals?.notDoneGoals ?? 0, border: true },
    { title: 'closed', value: userBusinessGoals?.doneGoals ?? 0, border: true },
    { title: 'Создано в этом месяце', value: userBusinessGoals?.currentGoals ?? 0, border: true },
    { title: 'Всего в этом месяце', value: userBusinessGoals?.transferredGoals ?? 0 },
    //TODO:   ====== Temporary Hidden ============
    // { title: 'Перенесено с прошлых месяцев', value: userBusinessGoals.transferredGoals },
  ];

  // ======== PlaningBusinessTasks =================================
  const mapTotalPlaningBusinessTasks: ITotalPlaningBusinessTasks[] = [
    { title: 'Всего запланировано задач', value: userBusinessTasks?.totalTasks },
    { title: 'Из них:', bold: '700' },
    {
      title: 'Запланированные задачи, над которыми велась работа',
      value: userBusinessTasks?.tasksWithCompletedWorkDays,
      leftIndent: '12px',
    },
    {
      title: 'Запланированные задачи, над которыми не велась работа',
      value: userBusinessTasks?.tasksWithNotCompletedWorkDays,
      leftIndent: '12px',
    },
  ];

  // ======== PlaningMeeting =================================
  const mapPlaningMeeting: ITotalPlaningBusinessTasks[] = [
    {
      title: 'Всего встреч в месяц',
      value: userPlaningMeeting?.totalMeetingsPerMonth ?? 0,
    },
    { title: 'Из них:', bold: '700' },
    {
      title: 'Организовано',
      value: userPlaningMeeting?.organizedMeetings ?? 0,
      leftIndent: '12px',
    },
    {
      title: 'Получено приглашений',
      value: userPlaningMeeting?.receivedMeetings ?? 0,
      svg: <UpArrowIcon />,
      leftIndent: '12px',
      accord: [
        {
          title: 'Принято',
          value: userPlaningMeeting?.acceptedInvitation ?? 0,
          leftIndent: '24px',
          color: '#5F6E67',
        },
        {
          title: 'Отклонено',
          value: userPlaningMeeting?.notAcceptedInvitation ?? 0,
          leftIndent: '24px',
          color: '#5F6E67',
        },
        {
          title: 'Не отвечено',
          value: userPlaningMeeting?.notReactInvitation ?? 0,
          leftIndent: '24px',
          color: '#5F6E67',
        },
      ],
    },
    {
      title: 'Не состоялось',
      value: userPlaningMeeting?.notNotTakePlaceEvents ?? 0,
      leftIndent: '12px',
    },
  ];
  const mapCurrentBusinessTasks: ITotalPlaningBusinessTasks[] = [
    { title: 'Готово', value: userBusinessTasks?.completedTasks },
    { title: 'Закрыто', value: userBusinessTasks?.closedTasks },
    { title: 'Открыто', value: userBusinessTasks?.openedTasks },
  ];
  // ======== ResultsOfWorkDays =================================
  const mapResultsOfWorkDays: ITotalPlaningBusinessTasks[] = [
    {
      title: 'Всего запланировано работ над задачами',
      value: userBusinessTasks?.totalPlannedWorkDays,
    },
    { title: 'Работы с отметкой занимался', value: userBusinessTasks?.completedWorkDays },
    {
      title: 'Не занимался по внешним причинам',
      value: userBusinessTasks?.externalReasonWorkDays,
    },
    {
      title: 'Не занимался по личным причинам',
      value: userBusinessTasks?.personalReasonWorkDays,
    },
    { title: 'Работы по незапланированным задачам', value: userBusinessTasks?.unplannedWorkDays },
  ];
  // ======== CreatingBusinessTasks =================================
  const mapCreatingBusinessTasks: ITotalPlaningBusinessTasks[] = [
    { title: 'Всего создано задач в течение месяца', value: userBusinessTasks?.createdTasks },
    { title: 'Из них:', bold: '700' },
    {
      title: 'Распланированные задачи',
      value: userBusinessTasks?.tasksWithWorkDays,
      leftIndent: '12px',
    },
    {
      title: 'Задачи без дней работы',
      value: userBusinessTasks?.tasksWithoutWorkDays,
      leftIndent: '12px',
    },
  ];
  // ======== PausedBusinessTasks =================================
  const mapSuspendedBusinessTasks: ITotalPlaningBusinessTasks[] = [
    { title: 'Всего приостановлено задач в течение месяца', value: 100 },
    { title: 'Из них:', bold: '700' },
    { title: 'Приостановлено самостоятельных бизнес задач', value: 30, leftIndent: '12px' },
    {
      title: 'Приостановлено делегированных на Мирошникову Анастасию Анатольевну ',
      value: 35,
      leftIndent: '12px',
      isUserName: true,
    },
    {
      title: 'Приостановлено делегированных от Мирошниковой Анастасии Анатольевны ',
      value: 45,
      leftIndent: '12px',
      isUserName: true,
    },
  ];
  // ======== ClosedBusinessTasks =================================
  const mapClosedBusinessTasks: ITotalPlaningBusinessTasks[] = [
    { title: 'Завершено с пометкой готово', value: userBusinessTasks?.totalCompletedTasks, bold: '700' },
    { title: 'Выполнено лично', value: userBusinessTasks?.personalCompletedTasks, leftIndent: '12px' },
    {
      title: 'Выполнено делегированных от ',
      value: userBusinessTasks?.delegatedCompletedTasks,
      leftIndent: '12px',
      isUserName: true,
    },
    { title: 'Закрыто без выполнения ', value: userBusinessTasks?.totalClosedTasks, bold: '700' },
    { title: 'Закрыто лично ', value: userBusinessTasks?.personalClosedTasks, leftIndent: '12px' },
    {
      title: 'Закрыто делегированных от ',
      value: userBusinessTasks?.delegatedClosedTasks,
      leftIndent: '12px',
      isUserName: true,
    },
  ];
  const mapDelegatedBusinessTasks: ITotalPlaningBusinessTasks[] = [
    {
      title: 'Задач делегировано на ',
      value: userBusinessTasks?.delegatedToTasks,
      isUserName: true,
    },
    {
      title: 'Задач делегировано от ',
      value: userBusinessTasks?.delegatedFromTasks,
      isUserName: true,
    },
  ];

  const numbersOpenClosedBusinessGoals: string = `${userBusinessGoals?.doneGoals ?? ''}/${
    userBusinessGoals?.notDoneGoals ?? ''
  }`;

  const mapTasksOfDay = [
    { title: 'totalWorkPlanned', value: userTasksOfDay?.totalTasks ?? 0, border: true },
    { title: 'wasDoingWork', value: userTasksOfDay?.completedTasks ?? 0, border: true },
    { title: 'notWorkPlanForExternalReasons', value: userTasksOfDay?.externalReasonTasks ?? 0, border: true },
    { title: 'notWorkPlanForPersonalReasons', value: userTasksOfDay?.personalReasonTasks ?? 0, border: true },
    { title: 'completedUnplannedTasks', value: userTasksOfDay?.unplannedTasks ?? 0 },
  ];

  const mapTasksOfMonth = [
    { title: 'totalWorkPlanned', value: userTasksOfMonth?.totalTasks ?? 0, border: true },
    { title: 'wasDoingWork', value: userTasksOfMonth?.completedTasks ?? 0, border: true },
    { title: 'notWorkPlanForExternalReasons', value: userTasksOfMonth?.externalReasonTasks ?? 0, border: true },
    { title: 'notWorkPlanForPersonalReasons', value: userTasksOfMonth?.personalReasonTasks ?? 0, border: true },
    { title: 'completedUnplannedTasks', value: userTasksOfMonth?.unplannedTasks ?? 0 },
  ];
  const result: ITableUserStatistics = {
    dataUserMonth: {
      mapPersonalGoals: personalGoals,
      textMonthGoal: textMonthGoal,
      mapBusinessGoals,
      planingBusinessTasks: {
        mapTotalPlaningBusinessTasks,
        mapCurrentBusinessTasks,
        // mapTasksOfMonth,
        mapCreatingBusinessTasks,
        mapSuspendedBusinessTasks,
        mapClosedBusinessTasks,
        mapResultsOfWorkDays,
      },
      mapPlaningMeeting,
      mapDelegatedBusinessTasks,
      mapDetailedBusinessGoals: userDetailedBusinessGoals ?? [],
      numbersOpenClosedBusinessGoals,
      // mapTasksOfMonth,
    },
    dataUserDay: {
      mapTasksOfDay,
    },
  };

  return result;
};

export default useTableUserStatistics;
